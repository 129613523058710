import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import BigNumber from "bignumber.js";
import { OracleParams, ValAddress } from "@terra-money/terra.js";
import { TerraValidator } from "types/validator";
import { TerraProposalItem } from "types/proposal";
import { useChainID, useNetwork } from "data/wallet";
import { useOracleParams } from "data/queries/oracle";
import { useNetworks } from "app/InitNetworks";
import { queryKey, RefetchOptions } from "../query";

export enum Aggregate {
	PERIODIC = "periodic",
	CUMULATIVE = "cumulative",
}

export enum AggregateStakingReturn {
	DAILY = "daily",
	ANNUALIZED = "annualized",
}

export enum AggregateWallets {
	TOTAL = "total",
	NEW = "new",
	ACTIVE = "active",
}

export const useTerraAPIURL = (isClassic?: true) => {
	const network = useNetwork();
	const networks = useNetworks();
	return isClassic ? networks["classic"].api : network.api;
};

// TODO: fix this
export const useFCDURL = () => {
	const chainID = useChainID();
	switch (chainID) {
		case "columbus-5":
			return "https://terra-classic-fcd.publicnode.com";
		case "pisco-1":
			return "https://pisco-fcd.terra.dev";
		case "rebel-2":
			return "https://lcd.luncblaze.com/";
		default:
			return "https://phoenix-fcd.terra.dev";
	}
};

export const useIsTerraAPIAvailable = () => {
	const url = useTerraAPIURL();
	return !!url;
};

export const useTerraAPI = <T>(path: string, params?: object, fallback?: T) => {
	const baseURL = useTerraAPIURL();
	const available = useIsTerraAPIAvailable();
	const shouldFallback = !available && fallback;

	return useQuery<T, AxiosError>({
		queryKey: [queryKey.TerraAPI, baseURL, path, params],
		queryFn: async () => {
			if (shouldFallback) return fallback;
			const { data } = await axios.get(path, { baseURL, params });
			return data;
		},
		...RefetchOptions.INFINITY,
		enabled: !!(baseURL || shouldFallback),
	});
};

/* fee */
export type GasPrices = Record<Denom, Amount>;

export const useGasPrices = () => {
	const baseURL = useFCDURL();
	const path = "/v1/txs/gas_prices";

	return useQuery({
		queryKey: [queryKey.TerraAPI, baseURL, path],
		queryFn: async () => {
			const { data } = await axios.get<GasPrices>(path, { baseURL });
			return data;
		},
		...RefetchOptions.INFINITY,
		enabled: !!baseURL,
	});
};

/* charts */
export enum ChartInterval {
	"1m" = "1m",
	"5m" = "5m",
	"15m" = "15m",
	"30m" = "30m",
	"1h" = "1h",
	"1d" = "1d",
}

export const useLunaPriceChart = (denom: Denom, interval: ChartInterval) => {
	return useTerraAPI<ChartDataItem[]>(`chart/price/${denom}`, { interval });
};

export const useTxVolume = (denom: Denom, type: Aggregate) => {
	return useTerraAPI<ChartDataItem[]>(`chart/tx-volume/${denom}/${type}`);
};

export const useStakingReturn = (type: AggregateStakingReturn) => {
	return useTerraAPI<ChartDataItem[]>(`chart/staking-return/${type}`);
};

export const useTaxRewards = (type: Aggregate) => {
	return useTerraAPI<ChartDataItem[]>(`chart/tax-rewards/${type}`);
};

export const useWallets = (walletsType: AggregateWallets) => {
	return useTerraAPI<ChartDataItem[]>(`chart/wallets/${walletsType}`);
};

export const useSumActiveWallets = () => {
	return useTerraAPI<Record<string, string>>(`chart/wallets/active/sum`);
};

/* validators */
export const useTerraValidators = () => {
	return useTerraAPI<TerraValidator[]>("validators", undefined, []);
};

export const useTerraValidator = (address: ValAddress) => {
	return useTerraAPI<TerraValidator>(`validators/${address}`);
};

export const useTerraProposal = (id: number) => {
	return useTerraAPI<TerraProposalItem[]>(`cosmos/gov/v1/proposals/${id}`);
};

/* helpers */
export const getCalcVotingPowerRate = (TerraValidators: TerraValidator[]) => {
	const total = BigNumber.sum(
		...TerraValidators.map(({ voting_power = 0 }) => voting_power)
	).toNumber();

	return (address: ValAddress) => {
		const validator = TerraValidators.find(
			({ operator_address }) => operator_address === address
		);

		if (!validator) return;
		const { voting_power } = validator;
		return voting_power ? Number(validator.voting_power) / total : undefined;
	};
};

export const calcSelfDelegation = (validator?: TerraValidator) => {
	if (!validator) return;
	const { self, tokens } = validator;
	return self ? Number(self) / Number(tokens) : undefined;
};

export const getCalcUptime = ({ slash_window }: OracleParams) => {
	return (validator?: TerraValidator) => {
		if (!validator) return;
		const { miss_counter } = validator;
		return miss_counter ? 1 - Number(miss_counter) / slash_window : undefined;
	};
};

export const useVotingPowerRate = (address: ValAddress) => {
	const { data: TerraValidators, ...state } = useTerraValidators();
	const calcRate = useMemo(() => {
		if (!TerraValidators) return;
		return getCalcVotingPowerRate(TerraValidators);
	}, [TerraValidators]);

	const data = useMemo(() => {
		if (!calcRate) return;
		return calcRate(address);
	}, [address, calcRate]);

	return { data, ...state };
};

export const useUptime = (validator: TerraValidator) => {
	const { data: oracleParams, ...state } = useOracleParams();

	const calc = useMemo(() => {
		if (!oracleParams) return;
		return getCalcUptime(oracleParams);
	}, [oracleParams]);

	const data = useMemo(() => {
		if (!calc) return;
		return calc(validator);
	}, [calc, validator]);

	return { data, ...state };
};
